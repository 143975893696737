// @ts-nocheck
import styled from 'styled-components/macro';

import { orientation } from '@/common/ui/theme/mixins';
import * as SidebarContentStyles from '@/common/modules/Sidebar/components/SidebarContent/SidebarContent.styles';
import * as HelpContainerStyles from '@/common/ui/HelpView/components/HelpContainer/HelpContainer.styles';

import * as FavouriteStyles from '#/modules/Favourite/Favourite.styles';
import * as HistoryStyles from '#/modules/History/History.styles';
import * as SettingsStyles from '#/modules/Settings/Settings.styles';
import * as AutoplayStyles from '#/modules/Autoplay/Autoplay.styles';
import * as StatisticsStyles from '#/modules/Statistics/Statistics.styles';

import { HelpLayout } from '../Help/components/HelpLayout/components/HelpView/components/HelpLayout/HelpLayout.styles';

export const SidebarsWrapper = styled.div`
   ${SidebarContentStyles.SidebarContentWrapper} {
      --sidebar-width: 600px;

      &:has(${HelpContainerStyles.HelpContainer}) {
         --sidebar-width: 820px;
      }

      &:has(${HelpLayout}) {
         --sidebar-width: 800px;
      }

      ${orientation.mobileLandscape`
        &:has(${FavouriteStyles.FavouriteWrapper}),
        &:has(${HistoryStyles.HistoryWrapper}),
        &:has(${StatisticsStyles.StatisticsWrapper}) {
            --sidebar-width: 640px;
        }

        &:has(${SettingsStyles.SettingsWrapper}),
        &:has(${AutoplayStyles.AutoplayWrapper}) {
            --sidebar-width: 430px;
        }

        &:has(${HelpContainerStyles.HelpContainer}) {
            --sidebar-padding-left: 5px;
            --sidebar-width: 660px;
        }
    `};

      ${orientation.portrait`
        &:has(${HelpContainerStyles.HelpContainer}) {
            --sidebar-padding-left: 5px;
        }
     `}
   }
`;
